<template>
  <el-form
    ref="teamInfoFormRef"
    :model="teamInfoForm"
    label-width="130px"
    class="setbox"
    size="small"
    :rules="teamInfoFormRul"
  >
    <el-form-item label="上传团队头像">
      <div class="addimg">
        <img-upload @change="getImg" class="getimgbtn" :ffixed="true" :ffixedNumber="[1.48,1]"></img-upload>
        <img :src="timageUrl" alt class="getedimg" />
      </div>
    </el-form-item>
    <el-form-item label="团队名称" prop="Team">
      <el-input v-model="teamInfoForm.Team"></el-input>
    </el-form-item>
    <el-form-item label="访问类型">
      <el-radio v-model="teamInfoForm.Recommend" :label="1">对所有人开放</el-radio>
    </el-form-item>
    <el-form-item label="团队负责人">
      <el-select v-model="teamInfoForm.Leader" placeholder="请选择">
        <el-option :label="item.Username" :value="item.Uid" v-for="item in listData"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="团队标语" prop="Slogan">
      <el-input type="textarea" v-model="teamInfoForm.Slogan" rows="4"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handelSaveTeamInfo('teamInfoFormRef')">保 存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  teamInfoRequest,
  saveTeamInfoRequest,
  teamMemberRequest
} from "@/api/project.js";
import ImgUpload from "@/components/Imgupload/imgUpload";
export default {
  data() {
    return {
      teamInfo: {},
      teamInfoForm: {
        Team: "",
        Recommend: 1,
        Leader: "",
        Slogan: ""
      },
      timageUrl: "",
      listData: [],
      teamInfoFormRul: {
        Team: [
          { required: true, message: "团队名称", trigger: "blur" },
          { min: 2, max: 60, message: "长度在 2 到 60 个字符", trigger: "blur" }
        ],
        Slogan: [
            { required: true, message: '请输入团队标语', trigger: 'blur' },
            { min: 2, message: '长度要大于2个字符', trigger: 'blur' }
          ],
        
      },
      blob: ""
    };
  },
  created() {
    this.getteamInfo(this.tid);
    this.getTeamMember(this.tid);
  },
  components: {
    ImgUpload
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async getteamInfo(tid) {
      try {
        const result = await teamInfoRequest({
          tid: tid
        });
        if (result.status == 200) {
          this.teamInfoForm = result.data.team;
           this.timageUrl = result.data.team.Cover
        }
      } catch (err) {}
    },
    async saveTeamInfo() {
      try {
        // var opt={
        //   cover:this.blob,
        //   tid:this.tid,
        //   team:this.teamInfoForm.Team,
        //   slogan:this.teamInfoForm.Slogan,
        //   leader:this.teamInfoForm.Leader,

        // }

        var formData = new FormData();
        formData.append("cover", this.blob);
        formData.append("tid", this.tid);
        formData.append("team", this.teamInfoForm.Team);
        formData.append("slogan", this.teamInfoForm.Slogan);
        formData.append("leader", this.teamInfoForm.Leader);
        const result = await saveTeamInfoRequest(formData);
        if (result.status == 200) {
          this.$message({
            message: `${result.message}`,
            type: "success"
          });
          // this.teamInfoForm = result.data.team;
        } else {
          this.$message({
            message: `${result.message}`,
            type: "warning"
          });
        }
      } catch (err) {}
    },
    handelSaveTeamInfo(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.saveTeamInfo();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getImg(url, data) {
      this.timageUrl = url;
      this.blob = data;
    },
    async getTeamMember(tid) {
      try {
        const result = await teamMemberRequest({
          tid: tid
        });
        if (result.status == 200) {
          this.teamInfoForm.leader = result.data.leader;
          this.listData = result.data.list;
        }
      } catch (err) {}
    }
  },
  computed: {
    tid() {
      return this.$route.params.tid || ""; //当前项目id
    }
  },
  watch: {
    timageUrl(val) {
      this.timageUrl = val;
    }
  }
};
</script>
<style  lang="less" scoped>
.setbox {
  padding: 15px;
  width: 600px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.addimg {
  width: 148px;
  height: 100px;
  position: relative;
  overflow: hidden;
  /deep/.el-upload-dragger{
    width:  148px;
  }
}
.getimgbtn {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}
.getedimg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
